import { ResetService } from "@/decorators/resetService.decorator";
import { policyList } from "@/enums/permissions";
import {
  Chart,
  DailyRetailSalesSheet,
  TileContent
} from "@/interfaces/dashboard";
import { messagesService } from "@/services/messages.service";
import { tilesService } from "@/services/tiles.service";
import {
  BooleanCheck,
  HelixDatePickerComponent,
  HelixDatePickerOptions
} from "helix-vue-components";
// @ts-ignore
import VueFlip from "vue-flip";
// @ts-ignore
import { GChart } from "vue-google-charts";
import { Component, Prop, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";
import {
  financialBreakdownMockData,
  financialSheetMockData
} from "../../dashboard.utils";
import { CardContainerComponent } from "./../CardContainer/CardContainer.component";
import Template from "./DailyFinancialBreakdown.template.vue";

@Component({
  mixins: [Template],
  components: {
    VueFlip,
    GChart,
    HelixDatePickerComponent,
    "card-container": CardContainerComponent
  }
})
@ResetService(tilesService)
export default class DailyFinancialBreakdownComponent extends Vue {
  @Prop({ required: true })
  public canEditGraphs!: boolean;
  @Prop({ required: true })
  public item!: object;
  @Prop({ required: true })
  public position!: number;
  @Getter("hasPermission", { namespace: "PermissionsModule" })
  public hasPermission!: BooleanCheck;
  public permission = false;
  public loading: boolean = false;
  public sheet: object | null = null;
  public date: string = new Date(new Date().setHours(0, 0, 0, 0)).toISOString();
  public options: Partial<HelixDatePickerOptions> = {
    clearable: false,
    disabled: this.canEditGraphs,
    "picker-options": {
      disabledDate(date) {
        return date > new Date();
      }
    }
  };
  public chart: Chart = {
    type: "ColumnChart",
    chartData: [],
    chartOptions: {
      colors: ["#199ff8", "#9C9C9C", "#a7dcff", "#1a6da5", "#313131"],
      legend: { position: "bottom", alignment: "start" }
    }
  };

  public mounted() {
    if (!this.canEditGraphs) {
      this.findTile();
    }
    if (this.canEditGraphs) {
      this.permission = !this.hasPermission(policyList.dailyFinancialBreakdown);
      this.loading = true;
      this.chart.chartData = financialBreakdownMockData;
      this.sheet = financialSheetMockData;
      setTimeout(() => {
        this.loading = false;
      }, 200);
    }
  }

  public async findTile() {
    this.loading = true;
    try {
      const tileContent = await tilesService.findTile(
        "financial_breakdown",
        2,
        {
          date: this.date
        }
      );

      if (tileContent === undefined) {
        this.loading = false;
        return;
      }

      const tile: TileContent = tileContent.content;
      // Chart
      this.chart.chartData = tilesService.formatTileData({
        tileData: tile.chart,
        labelX: "location",
        tileType: 2,
        labelParam: "label"
      });

      // Sheet
      const sheetData: DailyRetailSalesSheet = {
        columns: [],
        data: [],
        totals: {}
      };

      tile.sheet.labels!.forEach((label, index) => {
        sheetData.columns!.push(label.label);
      });

      sheetData.data = tile.sheet.series;

      this.sheet = sheetData;
      this.loading = false;
    } catch (e) {
      messagesService.renderErrorMessage(e);
    }
  }

  public haveHyphen(label: string): boolean {
    return label.includes("-");
  }

  public formatNegativeLabel(label: string) {
    return label.substring(2);
  }

  public formatLabel(label: string) {
    return label.substring(1);
  }

  public selectClass(label: string) {
    let classSelected: string = "";
    if (label === "total_profit_loss") {
      classSelected = "totalStyle";
    } else if (
      [
        "total_employee_cost",
        "total_overhead",
        "total_cash_in",
        "total_cash_out"
      ].includes(label)
    ) {
      classSelected = "subtitleStyle";
    }
    return classSelected;
  }
}
