import { fnsFormatToLocalDatetime } from "@/filters/date-fns.filter";
import { Timeclock } from "@/interfaces/timeclock";
import { Component, Prop, Vue } from "vue-property-decorator";
import Template from "./timeclockPrint.template.vue";

@Component({
  mixins: [Template],
  filters: {
    fnsFormatToLocalDatetime
  }
})
export class TimeclockPrintComponent extends Vue {
  @Prop()
  public timeclocks!: Timeclock[];

  public mounted() {
    this.$nextTick(() => this.$emit("readyToPrint"));
  }
}
