import { ResetService } from "@/decorators/resetService.decorator";
import { policyList } from "@/enums/permissions";
import { pusherEvents } from "@/enums/pusherEvents";
import { Location } from "@/interfaces/location";
import {
  PusherNotification,
  PusherNotificationData
} from "@/interfaces/notification";
import {
  DailyAction,
  PusherEventTypeMessage,
  PusherMessage
} from "@/interfaces/pusher";
import { User } from "@/interfaces/user";
import { tilesService } from "@/services/tiles.service";
import { FNS_DATE_FORMATS, fnsFormatDate } from "@/utils/date-fns.utils";
import {
  BooleanCheck,
  TablePagination,
  TablePaginationDefault
} from "helix-vue-components";
// @ts-ignore
import VueFlip from "vue-flip";
import { Component, Prop, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";
import { mockActionsFormatted } from "../../dashboard.utils";
import { CardContainerComponent } from "./../CardContainer/CardContainer.component";
import Template from "./DailyLocationActionTicker.template.vue";
@Component({
  mixins: [Template],
  components: {
    VueFlip,
    "card-container": CardContainerComponent
  },
  inject: ["$changes"]
})
@ResetService(tilesService)
export default class DailyLocationActionTickerComponent extends Vue {
  @Prop({ required: true })
  public canEditGraphs!: boolean;
  @Prop({ required: true })
  public item!: object;
  @Prop({ required: true }) public position!: number;

  @Getter("currentLocation", { namespace: "AuthModule" })
  public currentLocation!: Location;

  @Getter("hasPermission", { namespace: "PermissionsModule" })
  public hasPermission!: BooleanCheck;

  public permission = false;
  public actions: DailyAction[] = [];
  public loading = false;
  public isLoading = false;
  public pagination: TablePagination = { ...TablePaginationDefault };

  public events = [
    pusherEvents.orderPayment,
    pusherEvents.orderVoid,
    pusherEvents.customer,
    pusherEvents.batchAudit,
    pusherEvents.batchAction
  ];

  get actionsFormated() {
    if (this.actions.length === 16) {
      this.actions.splice(15, 1);
    }
    return [...this.actions];
  }

  get draggable() {
    return this.canEditGraphs;
  }

  protected async getMoreActions() {
    const listElm = this.$refs.scroll;
    if (
      !this.isLoading &&
      this.actions.length <= this.pagination.totalItems &&
      listElm &&
      "scrollTop" in listElm &&
      listElm.scrollTop + listElm.clientHeight >= listElm.scrollHeight
    ) {
      this.isLoading = true;
      const results: any[] = await tilesService.getLocationsPusherNotifications(
        this.actions.length / 15 + 1
      );
      this.pagination = await tilesService.getPagination();

      if (results.length > 0) {
        results.forEach((result: any) => {
          this.addMessage(result, true);
        });
        this.isLoading = false;
        return this.actionsFormated;
      }
      this.isLoading = false;
    }
  }

  protected async mounted() {
    if (!this.canEditGraphs) {
      // This endpoint should send notifications in same format as Pusher.
      this.loading = true;
      const notifications: PusherMessage[] = await tilesService.getLocationsPusherNotifications();
      this.pagination = await tilesService.getPagination(true);

      notifications.forEach(notification => {
        if (notification.user) {
          this.addMessage(notification, true);
        }
      });
      this.loading = false;
      this.events.forEach(event => {
        this.$changes.do(event, {
          callback: this.addMessage,
          displayForCurrentUser: true
        });
      });
    }

    if (this.canEditGraphs) {
      this.permission = !this.hasPermission(
        policyList.dailyLocationActionTicker
      );
      this.actions = mockActionsFormatted;
    }
  }

  /**
   * Adds a daily action to the list of actions
   * @param notification
   * @param value
   */
  protected addMessage(
    notification: PusherMessage | PusherNotification,
    push?: boolean
  ) {
    let actions: DailyAction[] = [];
    // @ts-ignore
    if (notification.message) {
      (notification as PusherNotification).message.reduce((acc, cur) => {
        if (Array.isArray(cur.payload)) {
          const actionArray = cur.payload.map((p, i) =>
            this.createDailyAction({
              ...cur,
              payload: (cur.payload as Array<{ [key: string]: any }>)[i]
            })
          );
          actions = [...actions, ...actionArray];
        } else {
          actions.push(this.createDailyAction(cur));
        }
        return actions;
      }, actions);
    } else {
      actions = [this.createDailyAction(notification as PusherMessage)];
    }

    this.actions = push
      ? [...this.actions, ...actions]
      : [...actions, ...this.actions];
  }

  /**
   * Creates daily action.
   * @param notificationMessage: PusherMessage | PusherNotification
   * @returns DailyAction
   */
  private createDailyAction(
    notificationMessage: PusherMessage | PusherNotificationData
  ): DailyAction {
    // @ts-ignore
    const notification = notificationMessage.object
      ? (notificationMessage as PusherMessage)
      : this.mapPusherNotificationToPusherMessage(
          notificationMessage as PusherNotificationData
        );

    // Date
    const time = fnsFormatDate(
      notification.created_at ? new Date(notification.created_at) : new Date(),
      FNS_DATE_FORMATS.BARS_FULL_DATE
    );

    // Name
    const userName = `${notification.user.first_name} ${
      notification.user.last_name
    }`;

    // Message
    const text = this.formatMessage(notification);
    // DailyAction
    return { time, userName, text };
  }

  /**
   * Formats the message to show within a daily action.
   * @param notification: PusherMessage
   * @returns string
   */
  private formatMessage(notification: PusherMessage): string {
    const eventSplit = notification.event_type.split("\\");
    let msgKey;
    if (eventSplit.length > 1) {
      msgKey = notification.event_type
        .split("\\")
        [eventSplit.length - 1].replace(/[::]+/g, "_");
    } else {
      msgKey = notification.event_type;
    }

    const data = this.prepareDataForTranslation(notification);

    const text = this.$tc(
      // @ts-ignore
      PusherEventTypeMessage[msgKey],
      data.count,
      data
    ).toString();

    return text;
  }

  private prepareDataForTranslation(notification: PusherMessage) {
    const model = notification.object_type.split("\\").reverse()[0];
    const count = notification.meta && notification.meta.count;
    const name = this.$tc(`pusher.model.${model}`, count, { count });

    return {
      ...notification.object,
      ...notification.meta,
      name
    };
  }

  /**
   * Maps pusher notification to pusher message.
   * @param notification: PusherNotification
   * @returns PusherMessage
   */
  private mapPusherNotificationToPusherMessage(
    notification: PusherNotificationData
  ): PusherMessage {
    return {
      created_at: notification!.created_at,
      object_type: notification!.object_type,
      event_type: notification!.event_type,
      object: notification!.payload,
      user: notification!.user as User,
      meta: notification!.meta
    };
  }
}
