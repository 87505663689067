import {
  Rank,
  RankComponent
} from "@/components/sharedComponents/rank/Rank.component";
import {
  SeparatedBreakdownComponent,
  SeparatedItem
} from "@/components/sharedComponents/separatedBreakdown/SeparatedBreakdown.component";
import { ResetService } from "@/decorators/resetService.decorator";
import { policyList } from "@/enums/permissions";
import {
  Chart,
  DailyRetailSalesSheet,
  TileContent,
  TileObjectdata
} from "@/interfaces/dashboard";
import { User } from "@/interfaces/user";
import { i18n } from "@/plugins/i18n";
import { messagesService } from "@/services/messages.service";
import { tilesService } from "@/services/tiles.service";
import { usersService } from "@/services/user.service";
import format from "date-fns/format";
import {
  BooleanCheck,
  HelixDatePickerComponent,
  HelixDatePickerOptions
} from "helix-vue-components";
import cloneDeep from "lodash/cloneDeep";
import every from "lodash/every";
import forOwn from "lodash/forOwn";
import map from "lodash/map";
import pull from "lodash/pull";
import remove from "lodash/remove";
import toArray from "lodash/toArray";
import unionBy from "lodash/unionBy";
// @ts-ignore
import VueFlip from "vue-flip";
// @ts-ignore
import { GChart } from "vue-google-charts";
import { Component, Prop, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";
import {
  mockRetailSalesChartData,
  mockRetailSalesDailySummary,
  mockRetailSalesEmployeeRank,
  mockRetailSalesSheet
} from "../../dashboard.utils";
import { CardContainerComponent } from "./../CardContainer/CardContainer.component";
import Template from "./DailyRetailSales.template.vue";

interface InputEmployeeRank {
  location_id: number;
  total?: number;
  average?: number;
  count?: number;
  user_id: number;
}

const emptyRank: Rank = {
  hasCurrency: false,
  icon: "",
  name: "",
  items: []
};

interface OutputEmployeeRank {
  average_ticket_price: InputEmployeeRank[];
  customers_served: InputEmployeeRank[];
  total_sales: InputEmployeeRank[];
}

interface PositionSales {
  hasSales: boolean;
  data: number[];
}

const emptyEmployeeRank: OutputEmployeeRank = {
  average_ticket_price: [],
  customers_served: [],
  total_sales: []
};
@Component({
  mixins: [Template],
  components: {
    VueFlip,
    HelixDatePickerComponent,
    GChart,
    SeparatedBreakdownComponent,
    RankComponent,
    "card-container": CardContainerComponent
  }
})
@ResetService(tilesService)
export default class DailyRetailSalesComponent extends Vue {
  @Prop({ required: true })
  public canEditGraphs!: boolean;
  @Prop({ required: true })
  public item!: object;
  @Prop({ required: true })
  public position!: number;
  @Getter("hasPermission", { namespace: "PermissionsModule" })
  public hasPermission!: BooleanCheck;
  public permission = false;
  public time: string = "by_the_half_hour";
  public loading: boolean = false;
  public sheet: DailyRetailSalesSheet | null = null;
  public date: string = new Date(new Date().setHours(0, 0, 0, 0)).toISOString();
  public mockData = mockRetailSalesChartData;
  public employeeRank: {
    average_ticket_price: Rank;
    customers_served: Rank;
    total_sales: Rank;
  } = {
    average_ticket_price: emptyRank,
    customers_served: emptyRank,
    total_sales: emptyRank
  };
  public chartDataOrigin: Array<Array<number | string>> = [];
  public dailySummary: SeparatedItem[] = [];
  public isFormat24h = false;
  public chart: Chart = {
    type: "AreaChart",
    chartData: [],
    chartOptions: {
      colors: ["rgb(0, 128, 58)", "#1A6DA5", "#0099FF"],
      width: "100%",
      height: "100%",
      chartArea: {
        bottom: "40px",
        left: "8%",
        right: "8%",
        top: "4%",
        width: "100%",
        height: "60%"
      },
      legend: {
        position: "right",
        textStyle: {
          fontSize: 12
        },
        maxLines: 3
      },
      backgroundColor: "transparent",
      tooltip: {
        textStyle: {
          color: "black"
        },
        isHtml: true
      },
      curveType: "none",
      hAxis: {
        slantedText: true,
        title: i18n.t("12_h_format").toString()
      },
      vAxis: {
        title: i18n.t("label_retail_sales").toString()
      }
    }
  };
  private users!: User[];
  private options: Partial<HelixDatePickerOptions> = {
    clearable: false,
    disabled: this.canEditGraphs,
    "picker-options": {
      disabledDate(date) {
        return date > new Date();
      }
    }
  };

  public mounted() {
    if (!this.canEditGraphs) {
      this.findTile();
    }
    if (this.canEditGraphs) {
      this.permission = !this.hasPermission(policyList.dailyRetailSales);
      this.loading = true;
      this.chart.chartData = this.mockData;
      this.employeeRank = mockRetailSalesEmployeeRank;
      this.sheet = mockRetailSalesSheet;
      this.dailySummary = mockRetailSalesDailySummary;
      setTimeout(() => {
        this.loading = false;
      }, 200);
    }
  }

  public async findTile() {
    this.loading = true;
    try {
      const tileContent = await tilesService.findTile("retail_sales", 1, {
        date: this.date,
        lapse: this.time
      });

      if (tileContent === undefined) {
        this.loading = false;
        return;
      }

      const employeeRank =
        (await tilesService.getEmployeesRank(this.date)) || emptyEmployeeRank;
      const dailySummary =
        (await tilesService.getDailySummary(this.date)) || this.dailySummary;
      this.dailySummary = Object.keys(dailySummary).map((key: string) => {
        const hasCurrency = !key.includes("customer");
        return {
          hasCurrency,
          name: key,
          amount: dailySummary[key]
        };
      });

      const aux: InputEmployeeRank[][] = [];
      forOwn(employeeRank, (arr: InputEmployeeRank[]) => {
        aux.push(arr || []);
      });
      const usersIds: number[] = unionBy(aux[0], aux[1], aux[2], "user_id").map(
        (rank: InputEmployeeRank) => rank.user_id
      );
      this.users = await usersService.getUsers(usersIds);
      this.makeRanking(employeeRank);
      const tile: TileContent = this.removeEmptyValues(tileContent.content);
      // Chart
      this.chartDataOrigin = tilesService.formatTileData(
        {
          tileData: tile.chart,
          labelX: "time",
          tileType: 1,
          labelParam: "key_code"
        },
        true
      );
      this.onChangeFormatChartData();
      // Sheet
      const sheetData: DailyRetailSalesSheet = {
        columns: [],
        rows: {},
        totals: {}
      };
      tile.sheet.labels!.forEach((label, index) => {
        sheetData.columns!.push(label.key_name);
        tile.sheet.dimensions!.forEach((dimension, j) => {
          const numberValue: string = tile.sheet!.series![index][
            dimension.code!
          ];
          !sheetData.rows![dimension.code!]
            ? (sheetData.rows![dimension.code!] = [numberValue])
            : sheetData.rows![dimension.code!].push(numberValue);
        });
      });
      sheetData.totals = tile.sheet.summary;
      this.sheet = sheetData;
      this.cleanTable();
      this.dailySummary = [
        ...this.dailySummary,
        { hasCurrency: false, name: "total", amount: this.sheet!.totals!.total }
      ];
      delete this.sheet!.totals!.total;

      if (Object.keys(this.sheet!.totals!).length > 1) {
        this.sheet!.totals!.total_sales = "";
      } else {
        this.sheet.totals = {};
      }
    } catch (e) {
      messagesService.renderErrorMessage(e);
    }
    this.loading = false;
  }

  /**
   * Removes values for columns equal to zero.
   */
  public cleanTable() {
    // Turns the rows to an array.
    const values = toArray(this.sheet!.rows!);

    // Creates object to easily order and check data.
    const position: { [key: number]: PositionSales } = {
      0: { hasSales: true, data: [] },
      1: { hasSales: true, data: [] },
      2: { hasSales: true, data: [] },
      3: { hasSales: true, data: [] }
    };

    // Push the values for each column in position object
    // as number to easily calculate the sum.
    values.forEach(field => {
      field.map((type, index) => {
        position[index].data.push(+type.replace("$", "").replace("-", ""));
      });
    });

    // Checks if the sum of every column value is equal
    // to zero. Is so, set the prop hasSales to false.
    Object.values(position).forEach((value, positionIndex) => {
      let total = 0;
      value.data.forEach((v, index) => {
        total += v;

        if (index === value.data.length - 1 && !total && positionIndex !== 0) {
          value.hasSales = false;
        }
      });
    });

    // If there are 2 columns in zero it means there are no online
    // and pre orders sales, so totals columns has to be removed too.
    if (pull(map(position, p => p.hasSales), true).length > 1) {
      const pos = Object.values(position);
      position[pos.length - 1].hasSales = false;
    }

    // Sets every value of columns to be removed to empty
    // strings in order to remove those easier.
    Object.values(this.sheet!.rows!).forEach(row => {
      Object.values(position).forEach((p, index) => {
        if (!p.hasSales) {
          row[index] = "";
        }
      });
    });

    // Finally removes every field that should not be shown.
    Object.values(this.sheet!.rows!).forEach(field => {
      pull(field, "");
    });
  }

  /**
   * @param data: TileContent
   * @returns TileContent
   * Removes every information about pre-order or online
   * sales if each of these are equal to zero.
   */
  public removeEmptyValues(data: TileContent): TileContent {
    if (every(data.chart.series, { pre_order: 0 })) {
      remove(
        data.chart.dimensions,
        (el: TileObjectdata) => el.code === "pre_order"
      );
      data.chart.series.forEach((el: TileObjectdata) => delete el.pre_order);
      delete data.sheet.summary.pre_order;
    }

    if (every(data.chart.series, { online: 0 })) {
      remove(
        data.chart.dimensions,
        (el: TileObjectdata) => el.code === "online"
      );
      data.chart.series.forEach((el: TileObjectdata) => delete el.online);
      delete data.sheet.summary.online;
    }

    return data;
  }

  public getUserById(id: number) {
    return this.users.filter((user: User) => user.id === id)[0];
  }

  public haveHyphen(label: string): boolean {
    return label.includes("-");
  }

  public formatNegativeLabel(label: string) {
    return label.substring(2);
  }

  public formatLabel(label: string) {
    return label.substring(1);
  }

  public rowStyle(i: number) {
    if (!i) {
      return { "border-top": "1px solid #c2c9d1" };
    }
  }
  public formatHour(data: Array<Array<number | string>>) {
    return data.map(h => {
      const newH = cloneDeep(h);
      newH[0] = this.convertionTime(newH[0].toString());
      return newH;
    });
  }

  public onChangeFormatChartData() {
    this.chart.chartData = this.isFormat24h
      ? cloneDeep(this.chartDataOrigin)
      : cloneDeep(this.formatHour(this.chartDataOrigin));
    this.chart.chartOptions!.hAxis!.title = (this.isFormat24h
      ? this.$t("24_h_format")
      : this.$t("12_h_format")
    ).toString();
  }

  private makeRanking(employeeRank: OutputEmployeeRank) {
    const averageTicketItems = this.getRankItems(
      employeeRank.average_ticket_price
    );
    const customerServedItems = this.getRankItems(
      employeeRank.customers_served
    );
    const totalSalesItems = this.getRankItems(employeeRank.total_sales);
    this.employeeRank.average_ticket_price = {
      hasCurrency: true,
      icon: "fal fa-tachometer-average",
      name: "average_ticket_price",
      items: averageTicketItems
    };
    this.employeeRank.customers_served = {
      hasCurrency: false,
      icon: "fal fa-person-carry",
      name: "customers_served",
      items: customerServedItems
    };
    this.employeeRank.total_sales = {
      hasCurrency: true,
      icon: "fal fa-usd-circle",
      name: "total_sales",
      items: totalSalesItems
    };
  }

  private getRankItems(rank: InputEmployeeRank[]) {
    return rank.map((r: InputEmployeeRank) => {
      const user = this.getUserById(r.user_id);
      return {
        label: `${user.last_name}, ${user.first_name}`,
        total: r.average || r.count || r.total || 0,
        avatar: user.avatar_thumb_url,
        avatarInitials: `${user.first_name[0]}${user.last_name[0]}`
      };
    });
  }

  private convertionTime(data: string) {
    const split = data.split(":");
    return split.length > 1
      ? format(new Date(0, 0, 0, +split[0], +split[1], 0), `hh:${split[1]} a`)
      : data;
  }
}
