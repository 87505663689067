// @ts-ignore
import VueFlip from "vue-flip";
import { Component, Prop, Vue } from "vue-property-decorator";
import Template from "./CardContainer.template.vue";

@Component({ mixins: [Template] })
export class CardContainerComponent extends Vue {
  // Position in dashboard tile
  @Prop({ required: true })
  public position!: number;

  // If true shows editing icons and disables all possible actions in tiles
  @Prop({ required: true })
  public canEditGraphs!: boolean;

  // If the tile is front or back
  @Prop({ default: "" }) public side!: string;

  @Prop({ required: true })
  public item!: object;

  @Prop({ required: true }) public flipRef!: string;

  // By default all tiles have 2 sides, if not you can pass this prop in false.
  @Prop({ default: true }) public twoSided!: boolean;

  // Validates if the user has access to the tile
  @Prop({ required: true }) public permission!: boolean;

  // Function to flip tiles front and back
  public flip(index: number) {
    const cardState = this.$parent.$parent.$refs[
      this.flipRef
    ] as typeof VueFlip;
    cardState.hover = !cardState.hover;
  }
}
