import { TimeclockPrintComponent } from "@/components/timeclock/timeclockPrint/timeclockPrint.component";
import { fnsFormatToLocalDatetime } from "@/filters/date-fns.filter";
import { Timeclock } from "@/interfaces/timeclock";
import { User } from "@/interfaces/user";
import { EventBus } from "@/internal";
import { Callback } from "@/types/types";
import addDays from "date-fns/addDays";
import addHours from "date-fns/addHours";
import addMinutes from "date-fns/addMinutes";
import addSeconds from "date-fns/addSeconds";
import getMinutes from "date-fns/fp/getMinutes";
import getHours from "date-fns/getHours";
import {
  HelixDatePickerComponent,
  HelixDatePickerOptions
} from "helix-vue-components";
import { Component, Prop, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import Template from "./TimeclockBack.template.vue";

@Component({
  mixins: [Template],
  components: { HelixDatePickerComponent },
  filters: {
    fnsFormatToLocalDatetime
  }
})
export class TimeclockBackComponent extends Vue {
  @Getter("user", { namespace: "AuthModule" })
  public user!: User;

  @Getter("loadingFiltered", { namespace: "TimeclockModule" })
  public loadingFiltered!: boolean;

  @Getter("userFilteredTimeclocks", { namespace: "TimeclockModule" })
  public userTimeclocks!: Timeclock[];

  @Action("filterUserTimeclocks", { namespace: "TimeclockModule" })
  public filterTimeclocksAction!: Callback;

  @Prop({ default: false }) public canEditGraphs!: boolean;
  public fromDate: string | null = null;
  public toDate: string | null = null;

  public get totalTime() {
    let duration: number | Date = new Date().setHours(0, 0, 0, 0);
    this.userTimeclocks.forEach(clock => {
      if (clock.work_for) {
        const arrayOfTime = clock.work_for.split(":");

        duration = addHours(duration, +arrayOfTime[0]);
        duration = addMinutes(duration, +arrayOfTime[1]);
        duration = addSeconds(duration, +arrayOfTime[2]);
      }
    });

    return `${getHours(duration)}:${getMinutes(duration)}`;
  }

  public get maxDate(): Partial<HelixDatePickerOptions> {
    return {
      disabled: this.canEditGraphs,
      "picker-options": {
        disabledDate: (date: Date) => {
          if (this.toDate) {
            return date.getTime() > new Date(this.toDate).getTime();
          }

          return date.getTime() > new Date().getTime();
        }
      }
    };
  }

  public get limitDates(): Partial<HelixDatePickerOptions> {
    return {
      disabled: this.canEditGraphs,
      "picker-options": {
        disabledDate: (date: Date) => {
          if (this.fromDate) {
            return (
              date.getTime() < new Date(this.fromDate).getTime() ||
              date.getTime() > new Date().getTime()
            );
          }

          return date.getTime() > new Date().getTime();
        }
      }
    };
  }

  public async edit(timeclock: Timeclock) {
    this.$router.push({
      name: "time-clock-change",
      params: {
        id: String(timeclock.id)
      }
    });
  }

  public isClosed(timeclock: Timeclock) {
    return timeclock.status === "CLOSED";
  }

  public mounted() {
    this.filterTimeclocks();
    EventBus.$on("hasCheckedOut", () => {
      this.filterTimeclocks();
    });
  }

  public print() {
    EventBus.$emit("print", {
      component: TimeclockPrintComponent,
      props: {
        timeclocks: this.userTimeclocks
      }
    });
  }

  private filterTimeclocks() {
    const query: any = {
      user_id: this.user.id,
      page: 1,
      per_page: 5,
      "q[status_eq]": "CLOSED"
    };

    if (this.fromDate) {
      query["q[check_in_gteq]"] = this.fromDate;
    }

    if (this.toDate) {
      // Passes next day at zero hour to cover the
      // whole rangetime from selected day.
      const date = addDays(new Date(this.toDate), 1).toISOString();
      query["q[check_in_lteq]"] = date;
    }

    this.filterTimeclocksAction(query);
  }
}
