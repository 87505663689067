var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('vue-flip',{ref:"flip_element",attrs:{"width":"100%","height":"100%"}},[_c('card-container',{attrs:{"slot":"front","side":"front","flipRef":"flip_element","canEditGraphs":_vm.canEditGraphs,"item":_vm.item,"position":_vm.position,"permission":_vm.permission},slot:"front"},[_c('template',{slot:"cardContent"},[_c('span',{staticClass:"timeclock__title hlx-section-title"},[_vm._v(_vm._s(_vm.$t("timeclock.timeclock")))]),_vm._v(" "),_c('h2',{staticClass:"timeclock__time"},[_vm._v(_vm._s(_vm.currentTime))]),_vm._v(" "),(!_vm.loading)?_c('div',{staticClass:"timeclock__actions"},[_c('div',{staticClass:"timeclock__current"},[(_vm.isWorking)?_c('span',{staticClass:"muted"},[_vm._v(_vm._s(_vm.$t("timeclock.hours_worked"))+":")]):_vm._e(),_vm._v(" "),(_vm.isWorking && !_vm.onBreak)?_c('timeclock-time-component',{attrs:{"startDate":_vm.lastTimeclock &&
                _vm.lastTimeclock.work_for === null &&
                _vm.lastTimeclock.check_in,"count":_vm.workCount}}):(_vm.isWorking && _vm.onBreak)?_c('span',[_vm._v(_vm._s(_vm.lastTimeclock && _vm.lastTimeclock.work_for))]):_vm._e(),_vm._v(" "),_c('br'),_vm._v(" "),_c('span',{staticClass:"muted"},[_vm._v(_vm._s(_vm.$t("timeclock.timeWeek"))+":"+_vm._s(_vm.userTime))])],1),_vm._v(" "),_c('div',{staticClass:"timeclock__buttons"},[(!_vm.isWorking)?_c('v-btn',{attrs:{"fab":"","small":"","disabled":_vm.canEditGraphs},on:{"click":_vm.startDay}},[_c('v-icon',[_vm._v("fal fa-clock")])],1):_vm._e(),_vm._v(" "),(_vm.isWorking)?_c('v-btn',{class:{ break: _vm.onBreak, pause: !_vm.onBreak },attrs:{"fab":"","small":"","disabled":_vm.canEditGraphs},on:{"click":_vm.toggleBreak}},[(_vm.onBreak)?_c('v-icon',{attrs:{"size":"14","color":"black"}},[_vm._v("fal fa-play")]):_c('v-icon',{attrs:{"size":"14","color":"black"}},[_vm._v("fal fa-pause")])],1):_vm._e(),_vm._v(" "),(_vm.isWorking)?_c('v-btn',{staticClass:"stop",attrs:{"fab":"","small":"","disabled":_vm.onBreak || _vm.canEditGraphs},on:{"click":_vm.endDay}},[_c('v-icon',{attrs:{"size":"14","color":"black"}},[_vm._v("fal fa-square")])],1):_vm._e()],1)]):_c('div',{staticClass:"loading"},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"25","color":"primary"}})],1),_vm._v(" "),(
          _vm.permission
            ? _vm.mockUserTimeClocks.length > 0
            : _vm.userTimeclocks.length > 0
        )?_c('div',{staticClass:"timeclock__table"},[_c('div',{staticClass:"timeclock__headers"},[_c('span',[_vm._v(_vm._s(_vm.$t("timeclock.checkin")))]),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t("timeclock.checkout")))]),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t("timeclock.break_for")))]),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t("total")))])]),_vm._v(" "),_c('div',{staticClass:"timeclock__record-container"},_vm._l((_vm.permission
              ? _vm.mockUserTimeClocks.slice(0, 5)
              : _vm.userTimeclocks.slice(0, 5)),function(record,i){return _c('div',{key:i,staticClass:"timeclock__record",class:{
              timeclock__active:
                i === 0 &&
                (record.status === 'OPENED' || record.status === 'BREAK_TIME'),
              fetching: _vm.loading && i === 0
            }},[_c('span',[_vm._v(_vm._s(_vm._f("fnsFormatToLocalDatetime")(record.check_in)))]),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm._f("fnsFormatToLocalDatetime")(record.check_out)))]),_vm._v(" "),(record.status !== 'CLOSED' && _vm.onBreak && i === 0)?_c('timeclock-time-component',{key:"break-elapsed",attrs:{"count":_vm.breakCount}}):_c('span',[_vm._v(_vm._s(record.break_for || "00:00:00"))]),_vm._v(" "),(i === 0 && _vm.isWorking && !_vm.onBreak && !record.status !== 'CLOSED')?_c('timeclock-time-component',{key:"worked-elapsed",attrs:{"count":_vm.workCount,"startDate":record && record.work_for === null && record.check_in}}):_c('span',[_vm._v(_vm._s(record.work_for || "00:00:00"))])],1)}),0)]):_c('div',{staticClass:"no-timeclocks"},[_c('span',[_vm._v(_vm._s(_vm.$t("timeclock.no_timeclocks")))])])])],2),_vm._v(" "),_c('card-container',{staticClass:"backside-container",attrs:{"slot":"back","side":"back","flipRef":"flip_element","item":_vm.item,"position":_vm.position,"canEditGraphs":_vm.canEditGraphs,"permission":_vm.permission},slot:"back"},[_c('template',{slot:"cardContent"},[_c('timeclock-back-component',{attrs:{"canEditGraphs":_vm.canEditGraphs}})],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }