import { TileData, TileQueryParams } from "@/interfaces/dashboard";
import { Settings, User } from "@/interfaces/user";
import { store } from "@/internal";
import HttpService from "@/services/http.service";
import { messagesService } from "@/services/messages.service";
import {
  FNS_DATE_FORMATS,
  fnsFormatDate,
  UTCTimeToLocalDate
} from "@/utils/date-fns.utils";
import { AxiosResponse } from "axios";
import Vue from "vue";

class TilesService extends HttpService {
  protected uri: string = ``;

  public async saveTilesConfig(tilesData: object[]) {
    try {
      const currentUser: User = store.getters["AuthModule/user"];
      const settings: Settings = {
        ...currentUser.settings,
        preferences: {
          tilesConfig: tilesData
        }
      };
      // @ts-ignore
      delete settings.tilesConfig;
      store.commit("AuthModule/setLoading", true);
      await Vue.axios({
        method: "PUT",
        url: `${process.env.VUE_APP_CORE_URL}/users/${currentUser.id}/settings`,
        data: settings
      });
      store.dispatch("AuthModule/updateCurrentUser", {
        ...currentUser,
        settings
      });
    } catch (e) {
      messagesService.renderErrorMessage(e);
    }
  }

  public async findTile(
    tileName: string,
    id: number,
    query: TileQueryParams
  ): Promise<any> {
    try {
      this.query.date = query.date;
      id === 1 ? (this.query.lapse = query.lapse) : delete this.query.lapse;
      this.uri = `/dashboard/tiles/${tileName}/${id}`;
      const response = await super.get();
      if (id === 2 && store.getters["LocationModule/locations"].length === 1) {
        return this.removeCombinatedLocations(response);
      }

      return response.data;
    } catch (e) {
      messagesService.renderErrorMessage(e);
    }
  }

  public async getEmployeesRank(date?: string) {
    try {
      const uriCopy = this.uri;
      this.uri = `/dashboard/employees_rank`;
      const response = await super.get({ date });
      this.uri = uriCopy;
      return response.data;
    } catch (e) {
      messagesService.renderErrorMessage(e);
    }
  }

  public async getDailySummary(date?: string) {
    try {
      const uriCopy = this.uri;
      this.uri = `/dashboard/daily_summary`;
      const response = await super.get({ date });
      this.uri = uriCopy;
      return response.data;
    } catch (e) {
      messagesService.renderErrorMessage(e);
    }
  }

  public formatTileData(data: TileChartActionData, hideZero = false) {
    const chartData: Array<Array<number | string>> = [];
    let firstSaleIndex = -1;
    let lastSaleIndex = -1;
    chartData.push([data.labelX]);
    data.tileData.labels!.forEach((label, index) => {
      if (data.tileType === 1) {
        const formattedTime = fnsFormatDate(
          UTCTimeToLocalDate(`${label[data.labelParam]}:00`),
          FNS_DATE_FORMATS.BASIC_TIME
        );
        chartData.push([formattedTime]);
      } else {
        chartData.push([label[data.labelParam]]);
      }

      data.tileData.dimensions!.forEach(dimension => {
        if (!chartData[0].includes(dimension.label!)) {
          chartData[0].push(dimension.label!);
        }
        const numberValue: string = data.tileData!.series![index][
          dimension.code!
        ];

        const numberToUse =
          data.tileType === 1 ? numberValue : Number(numberValue.split("$")[1]);
        chartData[index + 1].push(numberToUse);
        if (hideZero && numberToUse > 0) {
          if (firstSaleIndex > -1) {
            lastSaleIndex = index;
          } else {
            firstSaleIndex = index;
          }
        }
      });
    });
    if (hideZero && firstSaleIndex > -1) {
      lastSaleIndex = lastSaleIndex > -1 ? lastSaleIndex : firstSaleIndex + 1;
      return chartData.filter(
        (_, i) => i === 0 || (i >= firstSaleIndex && i <= lastSaleIndex + 2)
      );
    }
    return chartData;
  }

  public async getLocationsPusherNotifications(page = 1): Promise<any> {
    try {
      const query: { [key: string]: string | number } = {
        per_page: 15,
        page,
        embed: "user,object",
        "q[object_type_noteq]":
          "App\\Models\\Timeclock\\TimeclockChangeRequest",
        sort: "-created_at"
      };

      this.uri = `${process.env.VUE_APP_BASE_URL}/location_events`;
      const response = await super.get(query);
      return response.data.data;
    } catch (e) {
      messagesService.renderErrorMessage(e);
    }
    return [];
  }

  private removeCombinatedLocations(response: any) {
    const sheetSeries = [
      ...response.data.content.sheet.series.slice(
        0,
        response.data.content.sheet.series.length - 1
      )
    ];
    const sheetLabels = [
      ...response.data.content.sheet.labels.slice(
        0,
        response.data.content.sheet.labels.length - 1
      )
    ];
    const chartSeries = [
      ...response.data.content.chart.series.slice(
        0,
        response.data.content.chart.series.length - 1
      )
    ];
    const chartLabels = [
      ...response.data.content.chart.labels.slice(
        0,
        response.data.content.chart.labels.length - 1
      )
    ];
    return {
      content: {
        sheet: {
          dimensions: response.data.content.sheet.dimensions,
          series: sheetSeries,
          labels: sheetLabels
        },
        chart: {
          dimensions: response.data.content.chart.dimensions,
          series: chartSeries,
          labels: chartLabels
        }
      }
    };
  }
}

export const tilesService: TilesService = new TilesService();

export interface TileChartActionData {
  tileData: TileData;
  tileType: number | string;
  labelX: string;
  labelParam: string;
}
