import { Component, Prop, Vue } from "vue-property-decorator";
import Template from "./Rank.template.vue";

export interface Rank {
  hasCurrency: boolean;
  name: string;
  icon: string;
  items: RankItem[];
}

interface RankItem {
  avatar: string;
  label: string;
  total: string | number;
  avatarInitials: string;
}

@Component({
  mixins: [Template]
})
export class RankComponent extends Vue {
  @Prop({ default: () => null }) public rank!: Rank;

  public removeUrl(avatar: string) {
    this.rank.items.forEach((item: RankItem) => {
      if (item.avatar === item.avatar) {
        item.avatar = "";
      }
    });
  }
}
