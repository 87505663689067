import DailyFinancialBreakdownComponent from "@/components/dashboard/grid/dailyFinancialBreakdown/DailyFinancialBreakdown.component";
import DailyLocationActionTickerComponent from "@/components/dashboard/grid/dailyLocationActionTicker/DailyLocationActionTicker.component";
import { policyList } from "@/enums/permissions";
import { User } from "@/interfaces/user";
import { EventBus } from "@/internal";
import { tilesService } from "@/services/tiles.service";
import { BooleanCheck } from "@/types/types";
import sortBy from "lodash/sortBy";
// @ts-ignore
import VueFlip from "vue-flip";
// @ts-ignore
import { GChart } from "vue-google-charts";
// @ts-ignore
import VueGridLayout from "vue-grid-layout";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Getter } from "vuex-class";
import DailyRetailSalesComponent from "./dailyRetailSales/DailyRetailSales.component";
import Template from "./Grid.template.vue";
import TimeclockCardComponent from "./timeclockCard/TimeclockCard.component";

export interface LayoutItem {
  x: number;
  y: number;
  w: number;
  h: number;
  i: string;
  visible: boolean;
  timeclock?: boolean;
  dailyLocationActionTickerComponent?: boolean;
  dailyRetailSalesComponent?: boolean;
  dailyFinancialBreakdownComponent?: boolean;
}

const layoutDefault: LayoutItem[] = [
  {
    x: 0,
    y: 0,
    w: 1,
    h: 9,
    i: "0",
    timeclock: true,
    visible: true
  },
  {
    x: 1,
    y: 0,
    w: 1,
    h: 9,
    i: "1",
    dailyLocationActionTickerComponent: true,
    visible: true
  },
  {
    x: 0,
    y: 8,
    w: 1,
    h: 9,
    i: "2",
    dailyRetailSalesComponent: true,
    visible: true
  },
  {
    x: 0,
    y: 8,
    w: 1,
    h: 9,
    i: "3",
    dailyFinancialBreakdownComponent: true,
    visible: true
  }
];

@Component({
  mixins: [Template],
  components: {
    GridLayout: VueGridLayout.GridLayout,
    GridItem: VueGridLayout.GridItem,
    GChart,
    VueFlip,
    TimeclockCardComponent,
    DailyLocationActionTickerComponent,
    DailyRetailSalesComponent,
    DailyFinancialBreakdownComponent
  }
})
export default class GridComponent extends Vue {
  @Prop({ required: true }) public user!: User;
  @Prop({ required: true, default: false }) public canEditGraphs!: boolean;
  @Getter("hasPermission", { namespace: "PermissionsModule" })
  public hasPermission!: BooleanCheck;

  public layout: LayoutItem[] = [];
  public layoutToRender: LayoutItem[] = [];

  protected tilesCount = 4;

  public flip(index: number) {
    const array = this.$refs.flip_element as typeof VueFlip;
    array[index].hover = !array[index].hover;
  }

  public changeDragAndResize() {
    if (this.canEditGraphs) {
      tilesService.saveTilesConfig(this.layout);
    }
  }
  @Watch("canEditGraphs")
  public editWatch() {
    this.layoutToRender = [];
    this.$nextTick(() => {
      if (this.canEditGraphs) {
        this.layoutToRender = this.layout;
      } else {
        this.layoutToRender = this.filterLayout(this.layout);
      }
    });
  }

  protected mounted() {
    EventBus.$on("changeDragAndResize", this.changeDragAndResize);
    let layoutSaved;

    layoutSaved =
      this.user && this.user.settings.preferences!
        ? this.user.settings.preferences!.tilesConfig
          ? this.user.settings.preferences!.tilesConfig
          : this.layout
        : this.layout;

    this.layout =
      layoutSaved && layoutSaved.length === this.tilesCount
        ? layoutSaved.map((item: LayoutItem) => ({
            ...item,
            x: Number(item.x),
            y: Number(item.y),
            w: Number(item.w),
            h: Number(item.h)
          }))
        : layoutDefault;
    this.layoutToRender = this.filterLayout(this.layout);
  }

  protected filterLayout(layout: LayoutItem[]): LayoutItem[] {
    return this.sortLayout(
      layout.filter(
        item =>
          item.visible &&
          ((item.timeclock &&
            this.hasPermission(policyList.employeeTimeClock)) ||
            (item.dailyFinancialBreakdownComponent &&
              this.hasPermission(policyList.dailyFinancialBreakdown)) ||
            (item.dailyLocationActionTickerComponent &&
              this.hasPermission(policyList.dailyLocationActionTicker)) ||
            (item.dailyRetailSalesComponent &&
              this.hasPermission(policyList.dailyRetailSales)))
      )
    );
  }

  protected sortLayout(layout: LayoutItem[]): LayoutItem[] {
    const sorted = sortBy(layout, ["y", "x"]);
    const nogap = sorted.reduce((acc: LayoutItem[], item, index, array) => {
      if (index === 0) {
        item.y = 0;
      } else {
        if (item.x === 0) {
          item.y = array[index - 1].y + array[index - 1].h;
        } else {
          item.y = array[index - 1].y;
        }
      }
      if (item.w === 1 && array[index + 1] && array[index + 1].w === 1) {
        array[index + 1].y = item.y;
      }

      acc.push(item);

      return acc;
    }, []);
    return nogap;
  }
}
