// @ts-ignore
import {
  addTimeZeros,
  fnsDurationAsLocalTime,
  fnsParse
} from "@/utils/date-fns.utils";
import addSeconds from "date-fns/addSeconds";
import { Component, Prop, Vue } from "vue-property-decorator";
import Template from "./TimeclockTime.template.vue";

@Component({
  mixins: [Template]
})
export default class TimeclockTimeComponent extends Vue {
  @Prop({ default: false }) protected startDate!: string | Date;
  @Prop({ default: false }) protected count!: string;

  protected now = "";
  protected interval = 0;

  get baseTime(): Date {
    return (this.startDate && new Date(this.startDate)) || new Date();
  }

  protected mounted() {
    if (this.count && !this.startDate) {
      this.now = this.count;
    } else {
      this.now = fnsDurationAsLocalTime(this.baseTime, new Date());
    }
    this.interval = setInterval(() => this.addTime(), 1000);
  }

  get time() {
    return this.now;
  }

  protected addTime() {
    let date = fnsParse(this.now, "HH:mm:ss");
    date = addSeconds(date, 1);
    this.now = addTimeZeros(
      `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`
    );
  }

  protected beforeDestroy() {
    clearInterval(this.interval);
  }
}
