import { User } from "@/interfaces/user";
import { EventBus } from "@/internal";
import { traceabilityService } from "@/services/traceability.service";
import { Callback, PageNavAction } from "@/types/types";
import last from "lodash/last";
import { Component, Vue } from "vue-property-decorator";
import { Action, Getter, State } from "vuex-class";
import TraceabilityConfirm from "../sharedComponents/traceabilityConfirm/TraceabilityConfirm.component";
import Template from "./Dashboard.template.vue";
import GridComponent from "./grid/Grid.component";
@Component({
  mixins: [Template],
  components: {
    GridComponent
  }
})
export default class Dashboard extends Vue {
  public get parsedManagersName() {
    const displayName =
      (this.user && this.user.nickname) ||
      (this.user && this.user.first_name) ||
      "";
    const lastLetter = last(displayName);
    if (lastLetter && displayName.toLowerCase() === "s") {
      return `${displayName}' ${this.$t("dashboard")}`;
    } else {
      return `${displayName}'s ${this.$t("dashboard")}`;
    }
  }
  @Action("changeTitle", { namespace: "MegaMenuModule" })
  public changeTitleAction!: Callback;
  @Action("changeSubTitle", { namespace: "MegaMenuModule" })
  public changeSubTitleAction!: Callback;
  @Action("changeIcon", { namespace: "MegaMenuModule" })
  public changeIcon!: Callback;
  @Action("changeIconAction", { namespace: "MegaMenuModule" })
  public changeIconAction!: Callback;
  @Action("setPageNav", { namespace: "PageNavModule" })
  public setPageNav!: PageNavAction;

  @State("user", { namespace: "AuthModule" }) public user!: User;
  @Getter("loading", { namespace: "AuthModule" })
  public loading!: boolean;

  public canEditGraphs: boolean = false;
  public b: number = 0;

  constructor() {
    super();
    this.changeTitleAction("dashboard");
    this.changeIcon(
      require("@/assets/images/icon_primary_menu_dashboard@2x.png")
    );
  }

  public changeDragAndResize() {
    this.canEditGraphs = !this.canEditGraphs;
    EventBus.$emit("changeDragAndResize");
  }

  // cleaning title bar from icon
  public beforeDestroy() {
    this.changeIconAction(null);
  }

  public async validateCreds() {
    const locationId = +this.user.settings.current_location_id!;
    const traceData = await traceabilityService.getLocationTraceability(
      locationId
    );
    if (traceData[0].value === "Biotrack Traceability") {
      const traceStatusData = await traceabilityService.traceabilityCredsValidation();
      if (traceStatusData.code !== 200) {
        const response: boolean = await this.$modals.load(
          TraceabilityConfirm,
          { size: "normal", positionX: "center", positionY: "center" },
          {
            modalData: {
              status: {
                name: this.$t("biotrack_traceability.trace_error")
              },
              message: {
                name: this.$t("biotrack_traceability.credential_error")
              },
              description: {
                name: this.$t("biotrack_traceability.credential_update")
              },
              icon: {
                name: "fas fa-exclamation-triangle",
                size: "32",
                color: "warning"
              },
              title: {
                name: this.$t("biotrack_traceability.title")
              }
            }
          }
        );
        if (response) {
          window.location.href = `${
            process.env.VUE_APP_CORE_FRONTEND
          }/application-management/profile`;
        }
      }
    }
  }

  protected async created(): Promise<void> {
    EventBus.$emit("updateWidth", window.innerWidth);
    this.setPageNav({
      title: this.parsedManagersName,
      rightActions: {
        generalActions: () => [
          {
            icon: "fas fa-pen",
            action: this.changeDragAndResize
          }
        ]
      }
    });
    this.b++;
    this.validateCreds();
  }
}
