export const mockRetailSalesChartData = [
  ["time", "In Store Sales"],
  ["13:00", 0],
  ["13:30", 1.7],
  ["14:00", 0],
  ["14:30", 2.55],
  ["15:00", 0]
];

export const mockRetailSalesEmployeeRank = {
  average_ticket_price: {
    hasCurrency: true,
    icon: "fal fa-tachometer-average",
    name: "average_ticket_price",
    items: [
      {
        label: "Connor , John Admin",
        total: 2.125,
        avatar:
          "https://dev.static.core.helixcore.com/users/3/avatar/thumb/picture_1577201897.jpeg",
        avatarInitials: "JC"
      }
    ]
  },
  customers_served: {
    hasCurrency: false,
    icon: "fal fa-person-carry",
    name: "customers_served",
    items: [
      {
        label: "Connor , John Admin",
        total: 2,
        avatar:
          "https://dev.static.core.helixcore.com/users/3/avatar/thumb/picture_1577201897.jpeg",
        avatarInitials: "JC"
      }
    ]
  },
  total_sales: {
    hasCurrency: true,
    icon: "fal fa-usd-circle",
    name: "total_sales",
    items: [
      {
        label: "Connor , John Admin",
        total: 4.25,
        avatar:
          "https://dev.static.core.helixcore.com/users/3/avatar/thumb/picture_1577201897.jpeg",
        avatarInitials: "JC"
      }
    ]
  }
};

export const mockRetailSalesSheet = {
  columns: ["in_store", "online", "pre_order", "total"],
  rows: {
    sales: ["$4.25"],
    discounts: ["-$0.75"],
    loyalty: ["-$0"],
    refunds: ["-$0"],
    total_sales: ["$4.01"],
    cost_of_goods: ["-$187.23"],
    total_profit_loss: ["$182.98"]
  },
  totals: {
    "fill-row": ""
  }
};

export const mockRetailSalesDailySummary = [
  { hasCurrency: true, name: "average_ticket_price", amount: 2.125 },
  { hasCurrency: false, name: "customer_count", amount: 2 },
  { hasCurrency: false, name: "total", amount: 2 }
];
export const financialBreakdownMockData = [
  [
    "location",
    "Cash In",
    "Cash Out",
    "Overhead Cost",
    "Employee Cost",
    "Total Profit/Loss"
  ],
  ["Test Location #1", 41, 0, 0, 0, 907.22545190319],
  ["Test Location #2", 0, 0, 0, 0, 0],
  ["Test Location #3", 0, 0, 0, 0, 0],
  ["Test Location #3", 0, 0, 0, 0, 0],
  ["Location Cloning OFF", 0, 0, 0, 0, 0],
  ["Location New LG", 0, 0, 0, 0, 0],
  ["Benshi - Concentrate", 0, 0, 0, 0, 0],
  ["Testing Johan", 0, 0, 0, 0, 0],
  ["Testing Loc", 0, 0, 0, 0, 0],
  ["Test Location header", 0, 0, 0, 0, 0],
  ["Hernan's Location", 0, 0, 0, 0, 0],
  ["Test 204", 0, 0, 0, 0, 0],
  ["Test", 0, 0, 0, 0, 0],
  ["Testing Loc", 0, 0, 0, 0, 0],
  ["diana montoya", 0, 0, 0, 0, 0],
  ["TEST Location Emelson", 0, 0, 0, 0, 0],
  ["test 3", 0, 0, 0, 0, 0],
  ["A new location", 0, 0, 0, 0, 0],
  ["cristian-engeni", 0, 0, 0, 0, 0],
  ["HelixTech - Kanban", 0, 0, 0, 0, 0],
  ["Test Location Post", 0, 0, 0, 0, 0],
  ["Combined Locations", 41, 0, 0, 0, 907.22545190319]
];

export const financialSheetMockData = {
  columns: [
    "Test Location #1",
    "Test Location #2",
    "Test Location #3",
    "Test Location #3",
    "Location Cloning OFF",
    "Location New LG",
    "Benshi - Concentrate",
    "Testing Johan",
    "Testing Loc",
    "Test Location header",
    "Hernan's Location",
    "Test 204",
    "Test",
    "Testing Loc",
    "diana montoya",
    "TEST Location Emelson",
    "test 3",
    "A new location",
    "cristian-engeni",
    "HelixTech - Kanban",
    "Test Location Post",
    "Combined Locations"
  ],
  data: [
    {
      sales: "$576881.12561193",
      retail_sales: "$234.6256119266",
      outbound_wholesale: "$576646.5",
      total_cash_in: "$41",
      retail_refunds: "-$0",
      inbound_wholesale: "-$181472.69",
      total_cash_out: "$0",
      payouts: "$0",
      total_overhead: "$0",
      employees: [],
      total_employee_cost: "$0",
      total_profit_loss: "$907.22545190319"
    },
    {
      sales: "$0",
      retail_sales: "$0",
      outbound_wholesale: "$0",
      total_cash_in: "$0",
      retail_refunds: "-$0",
      inbound_wholesale: "-$0",
      total_cash_out: "$0",
      payouts: "$0",
      total_overhead: "$0",
      employees: [],
      total_employee_cost: "$0",
      total_profit_loss: "$0"
    },
    {
      sales: "$0",
      retail_sales: "$0",
      outbound_wholesale: "$0",
      total_cash_in: "$0",
      retail_refunds: "-$0",
      inbound_wholesale: "-$0",
      total_cash_out: "$0",
      payouts: "$0",
      total_overhead: "$0",
      employees: [],
      total_employee_cost: "$0",
      total_profit_loss: "$0"
    },
    {
      sales: "$0",
      retail_sales: "$0",
      outbound_wholesale: "$0",
      total_cash_in: "$0",
      retail_refunds: "-$0",
      inbound_wholesale: "-$0",
      total_cash_out: "$0",
      payouts: "$0",
      total_overhead: "$0",
      employees: [],
      total_employee_cost: "$0",
      total_profit_loss: "$0"
    },
    {
      sales: "$0",
      retail_sales: "$0",
      outbound_wholesale: "$0",
      total_cash_in: "$0",
      retail_refunds: "-$0",
      inbound_wholesale: "-$0",
      total_cash_out: "$0",
      payouts: "$0",
      total_overhead: "$0",
      employees: [],
      total_employee_cost: "$0",
      total_profit_loss: "$0"
    },
    {
      sales: "$0",
      retail_sales: "$0",
      outbound_wholesale: "$0",
      total_cash_in: "$0",
      retail_refunds: "-$0",
      inbound_wholesale: "-$0",
      total_cash_out: "$0",
      payouts: "$0",
      total_overhead: "$0",
      employees: [],
      total_employee_cost: "$0",
      total_profit_loss: "$0"
    },
    {
      sales: "$0",
      retail_sales: "$0",
      outbound_wholesale: "$0",
      total_cash_in: "$0",
      retail_refunds: "-$0",
      inbound_wholesale: "-$0",
      total_cash_out: "$0",
      payouts: "$0",
      total_overhead: "$0",
      employees: [],
      total_employee_cost: "$0",
      total_profit_loss: "$0"
    },
    {
      sales: "$0",
      retail_sales: "$0",
      outbound_wholesale: "$0",
      total_cash_in: "$0",
      retail_refunds: "-$0",
      inbound_wholesale: "-$0",
      total_cash_out: "$0",
      payouts: "$0",
      total_overhead: "$0",
      employees: [],
      total_employee_cost: "$0",
      total_profit_loss: "$0"
    },
    {
      sales: "$0",
      retail_sales: "$0",
      outbound_wholesale: "$0",
      total_cash_in: "$0",
      retail_refunds: "-$0",
      inbound_wholesale: "-$0",
      total_cash_out: "$0",
      payouts: "$0",
      total_overhead: "$0",
      employees: [],
      total_employee_cost: "$0",
      total_profit_loss: "$0"
    },
    {
      sales: "$0",
      retail_sales: "$0",
      outbound_wholesale: "$0",
      total_cash_in: "$0",
      retail_refunds: "-$0",
      inbound_wholesale: "-$0",
      total_cash_out: "$0",
      payouts: "$0",
      total_overhead: "$0",
      employees: [],
      total_employee_cost: "$0",
      total_profit_loss: "$0"
    },
    {
      sales: "$0",
      retail_sales: "$0",
      outbound_wholesale: "$0",
      total_cash_in: "$0",
      retail_refunds: "-$0",
      inbound_wholesale: "-$0",
      total_cash_out: "$0",
      payouts: "$0",
      total_overhead: "$0",
      employees: [],
      total_employee_cost: "$0",
      total_profit_loss: "$0"
    },
    {
      sales: "$0",
      retail_sales: "$0",
      outbound_wholesale: "$0",
      total_cash_in: "$0",
      retail_refunds: "-$0",
      inbound_wholesale: "-$0",
      total_cash_out: "$0",
      payouts: "$0",
      total_overhead: "$0",
      employees: [],
      total_employee_cost: "$0",
      total_profit_loss: "$0"
    },
    {
      sales: "$0",
      retail_sales: "$0",
      outbound_wholesale: "$0",
      total_cash_in: "$0",
      retail_refunds: "-$0",
      inbound_wholesale: "-$0",
      total_cash_out: "$0",
      payouts: "$0",
      total_overhead: "$0",
      employees: [],
      total_employee_cost: "$0",
      total_profit_loss: "$0"
    },
    {
      sales: "$0",
      retail_sales: "$0",
      outbound_wholesale: "$0",
      total_cash_in: "$0",
      retail_refunds: "-$0",
      inbound_wholesale: "-$0",
      total_cash_out: "$0",
      payouts: "$0",
      total_overhead: "$0",
      employees: [],
      total_employee_cost: "$0",
      total_profit_loss: "$0"
    },
    {
      sales: "$0",
      retail_sales: "$0",
      outbound_wholesale: "$0",
      total_cash_in: "$0",
      retail_refunds: "-$0",
      inbound_wholesale: "-$0",
      total_cash_out: "$0",
      payouts: "$0",
      total_overhead: "$0",
      employees: [],
      total_employee_cost: "$0",
      total_profit_loss: "$0"
    },
    {
      sales: "$0",
      retail_sales: "$0",
      outbound_wholesale: "$0",
      total_cash_in: "$0",
      retail_refunds: "-$0",
      inbound_wholesale: "-$0",
      total_cash_out: "$0",
      payouts: "$0",
      total_overhead: "$0",
      employees: [],
      total_employee_cost: "$0",
      total_profit_loss: "$0"
    },
    {
      sales: "$0",
      retail_sales: "$0",
      outbound_wholesale: "$0",
      total_cash_in: "$0",
      retail_refunds: "-$0",
      inbound_wholesale: "-$0",
      total_cash_out: "$0",
      payouts: "$0",
      total_overhead: "$0",
      employees: [],
      total_employee_cost: "$0",
      total_profit_loss: "$0"
    },
    {
      sales: "$0",
      retail_sales: "$0",
      outbound_wholesale: "$0",
      total_cash_in: "$0",
      retail_refunds: "-$0",
      inbound_wholesale: "-$0",
      total_cash_out: "$0",
      payouts: "$0",
      total_overhead: "$0",
      employees: [],
      total_employee_cost: "$0",
      total_profit_loss: "$0"
    },
    {
      sales: "$0",
      retail_sales: "$0",
      outbound_wholesale: "$0",
      total_cash_in: "$0",
      retail_refunds: "-$0",
      inbound_wholesale: "-$0",
      total_cash_out: "$0",
      payouts: "$0",
      total_overhead: "$0",
      employees: [],
      total_employee_cost: "$0",
      total_profit_loss: "$0"
    },
    {
      sales: "$0",
      retail_sales: "$0",
      outbound_wholesale: "$0",
      total_cash_in: "$0",
      retail_refunds: "-$0",
      inbound_wholesale: "-$0",
      total_cash_out: "$0",
      payouts: "$0",
      total_overhead: "$0",
      employees: [],
      total_employee_cost: "$0",
      total_profit_loss: "$0"
    },
    {
      sales: "$0",
      retail_sales: "$0",
      outbound_wholesale: "$0",
      total_cash_in: "$0",
      retail_refunds: "-$0",
      inbound_wholesale: "-$0",
      total_cash_out: "$0",
      payouts: "$0",
      total_overhead: "$0",
      employees: [],
      total_employee_cost: "$0",
      total_profit_loss: "$0"
    },
    {
      sales: "$576881.12561193",
      retail_sales: "$234.6256119266",
      outbound_wholesale: "$576646.5",
      total_cash_in: "$41",
      retail_refunds: "-$0",
      inbound_wholesale: "-$181472.69",
      total_cash_out: "$0",
      payouts: "$0",
      total_overhead: "$0",
      employees: [],
      total_employee_cost: "$0",
      total_profit_loss: "$907.22545190319"
    }
  ],
  totals: {}
};

export const mockActionsFormatted = [
  {
    time: "11:36 AM",
    userName: "John Admin Connor ",
    text: ". Checked the customer Lilliann Brekke."
  },
  {
    time: "11:34 AM",
    userName: "Bensh GM",
    text: ". Checked the customer null."
  }
];

export const mockUserTimeClock = [
  {
    id: 773,
    user_id: 276,
    account_id: 1,
    location_id: 1,
    status: "CLOSED",
    check_in: "2020-06-17T20:21:25+00:00",
    check_out: "2020-06-17T20:21:29+00:00",
    work_for: "00:00:04",
    break_for: null,
    created_by: 276,
    updated_by: 276,
    deleted_by: null,
    created_at: "2020-06-17T20:21:25+00:00",
    updated_at: "2020-06-17T20:21:29+00:00",
    deleted_at: null,
    timeclock_events: [
      {
        id: 2224,
        timeclock_id: 773,
        event: "CHECK_IN",
        registered_at: "2020-06-17T20:21:25+00:00",
        created_by: 276,
        updated_by: null,
        deleted_by: null,
        created_at: "2020-06-17T20:21:25+00:00",
        updated_at: "2020-06-17T20:21:25+00:00",
        deleted_at: null
      },
      {
        id: 2225,
        timeclock_id: 773,
        event: "CHECK_OUT",
        registered_at: "2020-06-17T20:21:29+00:00",
        created_by: 276,
        updated_by: null,
        deleted_by: null,
        created_at: "2020-06-17T20:21:29+00:00",
        updated_at: "2020-06-17T20:21:29+00:00",
        deleted_at: null
      }
    ]
  },
  {
    id: 769,
    user_id: 276,
    account_id: 1,
    location_id: 1,
    status: "CLOSED",
    check_in: "2020-05-27T15:28:32+00:00",
    check_out: "2020-05-27T15:28:42+00:00",
    work_for: "00:00:07",
    break_for: "00:00:03",
    created_by: 276,
    updated_by: 276,
    deleted_by: null,
    created_at: "2020-05-27T15:28:32+00:00",
    updated_at: "2020-05-27T15:28:42+00:00",
    deleted_at: null,
    timeclock_events: [
      {
        id: 2209,
        timeclock_id: 769,
        event: "CHECK_IN",
        registered_at: "2020-05-27T15:28:32+00:00",
        created_by: 276,
        updated_by: null,
        deleted_by: null,
        created_at: "2020-05-27T15:28:33+00:00",
        updated_at: "2020-05-27T15:28:33+00:00",
        deleted_at: null
      },
      {
        id: 2210,
        timeclock_id: 769,
        event: "BREAK_TIME_IN",
        registered_at: "2020-05-27T15:28:36+00:00",
        created_by: 276,
        updated_by: null,
        deleted_by: null,
        created_at: "2020-05-27T15:28:36+00:00",
        updated_at: "2020-05-27T15:28:36+00:00",
        deleted_at: null
      },
      {
        id: 2211,
        timeclock_id: 769,
        event: "BREAK_TIME_OUT",
        registered_at: "2020-05-27T15:28:39+00:00",
        created_by: 276,
        updated_by: null,
        deleted_by: null,
        created_at: "2020-05-27T15:28:39+00:00",
        updated_at: "2020-05-27T15:28:39+00:00",
        deleted_at: null
      },
      {
        id: 2212,
        timeclock_id: 769,
        event: "CHECK_OUT",
        registered_at: "2020-05-27T15:28:42+00:00",
        created_by: 276,
        updated_by: null,
        deleted_by: null,
        created_at: "2020-05-27T15:28:42+00:00",
        updated_at: "2020-05-27T15:28:42+00:00",
        deleted_at: null
      }
    ]
  },
  {
    id: 756,
    user_id: 276,
    account_id: 1,
    location_id: 1,
    status: "CLOSED",
    check_in: "2020-05-04T13:18:04+00:00",
    check_out: "2020-05-14T19:56:53+00:00",
    work_for: "00:00:07",
    break_for: "06:38:42",
    created_by: 276,
    updated_by: 276,
    deleted_by: null,
    created_at: "2020-05-04T13:18:04+00:00",
    updated_at: "2020-05-14T19:56:53+00:00",
    deleted_at: null,
    timeclock_events: [
      {
        id: 2183,
        timeclock_id: 756,
        event: "CHECK_IN",
        registered_at: "2020-05-04T13:18:04+00:00",
        created_by: 276,
        updated_by: null,
        deleted_by: null,
        created_at: "2020-05-04T13:18:04+00:00",
        updated_at: "2020-05-04T13:18:04+00:00",
        deleted_at: null
      },
      {
        id: 2184,
        timeclock_id: 756,
        event: "BREAK_TIME_IN",
        registered_at: "2020-05-04T13:18:07+00:00",
        created_by: 276,
        updated_by: null,
        deleted_by: null,
        created_at: "2020-05-04T13:18:07+00:00",
        updated_at: "2020-05-04T13:18:07+00:00",
        deleted_at: null
      },
      {
        id: 2192,
        timeclock_id: 756,
        event: "BREAK_TIME_OUT",
        registered_at: "2020-05-14T19:56:49+00:00",
        created_by: 276,
        updated_by: null,
        deleted_by: null,
        created_at: "2020-05-14T19:56:49+00:00",
        updated_at: "2020-05-14T19:56:49+00:00",
        deleted_at: null
      },
      {
        id: 2193,
        timeclock_id: 756,
        event: "CHECK_OUT",
        registered_at: "2020-05-14T19:56:53+00:00",
        created_by: 276,
        updated_by: null,
        deleted_by: null,
        created_at: "2020-05-14T19:56:53+00:00",
        updated_at: "2020-05-14T19:56:53+00:00",
        deleted_at: null
      }
    ]
  },
  {
    id: 755,
    user_id: 276,
    account_id: 1,
    location_id: 1,
    status: "CLOSED",
    check_in: "2020-05-04T13:13:40+00:00",
    check_out: "2020-05-04T13:18:02+00:00",
    work_for: "00:03:36",
    break_for: "00:00:46",
    created_by: 276,
    updated_by: 276,
    deleted_by: null,
    created_at: "2020-05-04T13:13:40+00:00",
    updated_at: "2020-05-04T13:18:02+00:00",
    deleted_at: null,
    timeclock_events: [
      {
        id: 2179,
        timeclock_id: 755,
        event: "CHECK_IN",
        registered_at: "2020-05-04T13:13:40+00:00",
        created_by: 276,
        updated_by: null,
        deleted_by: null,
        created_at: "2020-05-04T13:13:40+00:00",
        updated_at: "2020-05-04T13:13:40+00:00",
        deleted_at: null
      },
      {
        id: 2180,
        timeclock_id: 755,
        event: "BREAK_TIME_IN",
        registered_at: "2020-05-04T13:15:00+00:00",
        created_by: 276,
        updated_by: null,
        deleted_by: null,
        created_at: "2020-05-04T13:15:00+00:00",
        updated_at: "2020-05-04T13:15:00+00:00",
        deleted_at: null
      },
      {
        id: 2181,
        timeclock_id: 755,
        event: "BREAK_TIME_OUT",
        registered_at: "2020-05-04T13:15:46+00:00",
        created_by: 276,
        updated_by: null,
        deleted_by: null,
        created_at: "2020-05-04T13:15:46+00:00",
        updated_at: "2020-05-04T13:15:46+00:00",
        deleted_at: null
      },
      {
        id: 2182,
        timeclock_id: 755,
        event: "CHECK_OUT",
        registered_at: "2020-05-04T13:18:02+00:00",
        created_by: 276,
        updated_by: null,
        deleted_by: null,
        created_at: "2020-05-04T13:18:02+00:00",
        updated_at: "2020-05-04T13:18:02+00:00",
        deleted_at: null
      }
    ]
  },
  {
    id: 754,
    user_id: 276,
    account_id: 1,
    location_id: 1,
    status: "CLOSED",
    check_in: "2020-05-04T12:58:02+00:00",
    check_out: "2020-05-04T13:13:19+00:00",
    work_for: "00:00:11",
    break_for: "00:15:06",
    created_by: 276,
    updated_by: 276,
    deleted_by: null,
    created_at: "2020-05-04T12:58:02+00:00",
    updated_at: "2020-05-04T13:13:19+00:00",
    deleted_at: null,
    timeclock_events: [
      {
        id: 2173,
        timeclock_id: 754,
        event: "CHECK_IN",
        registered_at: "2020-05-04T12:58:02+00:00",
        created_by: 276,
        updated_by: null,
        deleted_by: null,
        created_at: "2020-05-04T12:58:02+00:00",
        updated_at: "2020-05-04T12:58:02+00:00",
        deleted_at: null
      },
      {
        id: 2174,
        timeclock_id: 754,
        event: "BREAK_TIME_IN",
        registered_at: "2020-05-04T12:58:08+00:00",
        created_by: 276,
        updated_by: null,
        deleted_by: null,
        created_at: "2020-05-04T12:58:08+00:00",
        updated_at: "2020-05-04T12:58:08+00:00",
        deleted_at: null
      },
      {
        id: 2175,
        timeclock_id: 754,
        event: "BREAK_TIME_OUT",
        registered_at: "2020-05-04T12:58:26+00:00",
        created_by: 276,
        updated_by: null,
        deleted_by: null,
        created_at: "2020-05-04T12:58:26+00:00",
        updated_at: "2020-05-04T12:58:26+00:00",
        deleted_at: null
      },
      {
        id: 2176,
        timeclock_id: 754,
        event: "BREAK_TIME_IN",
        registered_at: "2020-05-04T12:58:28+00:00",
        created_by: 276,
        updated_by: null,
        deleted_by: null,
        created_at: "2020-05-04T12:58:28+00:00",
        updated_at: "2020-05-04T12:58:28+00:00",
        deleted_at: null
      },
      {
        id: 2177,
        timeclock_id: 754,
        event: "BREAK_TIME_OUT",
        registered_at: "2020-05-04T13:13:16+00:00",
        created_by: 276,
        updated_by: null,
        deleted_by: null,
        created_at: "2020-05-04T13:13:16+00:00",
        updated_at: "2020-05-04T13:13:16+00:00",
        deleted_at: null
      },
      {
        id: 2178,
        timeclock_id: 754,
        event: "CHECK_OUT",
        registered_at: "2020-05-04T13:13:19+00:00",
        created_by: 276,
        updated_by: null,
        deleted_by: null,
        created_at: "2020-05-04T13:13:19+00:00",
        updated_at: "2020-05-04T13:13:19+00:00",
        deleted_at: null
      }
    ]
  }
];
