import { Component, Prop, Vue } from "vue-property-decorator";
import Template from "./SeparatedBreakdown.template.vue";

export interface SeparatedItem {
  hasCurrency: boolean;
  name: string;
  amount: string | number | (() => string | number);
}

@Component({
  mixins: [Template]
})
export class SeparatedBreakdownComponent extends Vue {
  @Prop({ default: () => [] }) public items!: SeparatedItem[];
  @Prop({ default: () => "" }) public title!: string;
  @Prop({ default: false }) public loading!: () => boolean;

  public amount(amount: string | number | (() => string | number)) {
    if (typeof amount === "function") {
      return amount();
    }

    return amount;
  }
}
