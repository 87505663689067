import { User } from "@/interfaces/user";
import { Callback } from "@/types/types";

export interface DailyAction {
  time: string;
  text: string;
  userName: string;
}

export interface PusherMessage {
  account_id?: number;
  created_at: string;
  event_type: string;
  id?: number;
  location_id?: number;
  object: any;
  object_id?: number;
  object_type: string;
  user: User;
  user_id?: number;
  meta: any;
}

export enum PusherEventTypeMessage {
  NotifyOrderPayment = "pusher.complete_order_message",
  Create = "pusher.customer_create_message",
  Update = "pusher.customer_update_message",
  CustomerCheckIn = "pusher.customer_checkin_message",
  CustomerCheckOut = "pusher.customer_checkout_message",
  CustomerRemoveFromCheckIn = "pusher.customer_checkout_message",
  PointOfSaleSelectCustomer = "pusher.customer_select_from_pos",
  SaveRetailSale = "pusher.save_retail_sale",
  CompleteRetailSale = "pusher.complete_retail_sale",
  OrderPaymentModified = "pusher.modify_retail_sale",
  OrderRefund = "pusher.refund_retail_sale",
  OrderVoid = "pusher.void_retail_sale",
  ModifiedRetailSetting = "pusher.modify_retail_settings",
  CreateDiscountProgram = "pusher.create_discount_program",
  ModifyDiscountProgram = "pusher.modify_discount_program",
  DeleteDiscountProgram = "pusher.delete_discount_program",
  CreateLoyaltyProgram = "pusher.modify_loyalty_program",
  ModifyLoyaltyProgram = "pusher.modify_loyalty_program",
  DeleteLoyaltyProgram = "pusher.modify_loyalty_program",
  ModifyLoyaltyPoints = "pusher.modify_loyalty_points",
  CreateBonusPoint = "pusher.modify_bonus_point",
  ModifyBonusPoint = "pusher.modify_bonus_point",
  DeleteBonusPoint = "pusher.modify_bonus_point",
  ModifySalesLimits = "pusher.modify_sales_limits",
  Delete = "pusher.customer_delete_message",
  BatchAdjustment = "pusher.batch_adjustment",
  BatchAudit = "pusher.batch_audit",
  BatchCombine = "pusher.batch_combine",
  BatchConvert = "pusher.batch_convert",
  BatchMove = "pusher.batch_move",
  CreateTemplate = "pusher.create_template",
  UpdateTemplate = "pusher.update_template",
  DeleteTemplate = "pusher.delete_template",
  Import = "pusher.import",
  ImportSamples = "pusher.import_samples",
  Export = "pusher.export",
  UpdateDataSharing = "pusher.update_data_sharing",
  MoneyDepositToSafe = "pusher.money.deposit_to_safe",
  MoneyUndoAddMoneyToSafe = "pusher.money.undo_deposit_to_safe",
  MoneyRemoveFromSafe = "pusher.money.remove_from_safe",
  MoneyUndoRemoveMoneyFromSafe = "pusher.money.undo_remove_from_safe",
  MoneyPayoutFromSafe = "pusher.money.payout_from_safe",
  MoneyUndoPayoutFromSafe = "pusher.money.undo_payout_from_safe",
  MoneyOpenTill = "pusher.money.open_till",
  MoneyCloseTill = "pusher.money.close_till",
  MoneyDepositToTill = "pusher.money.deposit_to_till",
  MoneyUndoAddMoneyFromTill = "pusher.money.undo_deposit_to_till",
  MoneyRemoveFromTill = "pusher.money.remove_from_till",
  MoneyUndoRemoveMoneyFromTill = "pusher.money.undo_remove_from_till",
  MoneyPayoutFromTill = "pusher.money.payout_from_till",
  MoneyVoidFromTill = "pusher.money.void_from_till",
  MoneyReviewCount = "pusher.money.review_count",
  MoneyReviewRecount = "pusher.money.review_recount",
  MoneyEmployeeCount = "pusher.money.employee_count",
  PricePointTool = "pusher.price_point_tool",
  CreateBatchLevelPrice = "pusher.create_batch_level_price",
  ModifyBatchLevelPrice = "pusher.modify_batch_level_price",
  DeleteBatchLevelPrice = "pusher.delete_batch_level_price",
  MultipleDeleteBatchLevelPrice = "pusher.multiple_delete_batch_level_price",
  CreateMemberLevelPrice = "pusher.create_member_level_price",
  ModifyMemberLevelPrice = "pusher.modify_member_level_price",
  DeleteMemberLevelPrice = "pusher.delete_member_level_price",
  MultipleDeleteMemberLevel = "pusher.multiple_delete_member_level_price",
  CreateTaxCategory = "pusher.create_tax_category",
  ModifyTaxCategory = "pusher.modify_tax_category",
  DeleteTaxCategory = "pusher.delete_tax_category",
  ModifyPricingOptions = "pusher.modify_pricing_options",
  PreOrderFilled = "order_fulfillment.preorder_filled",
  PreOrderUnfilled = "order_fulfillment.preorder_unfilled",
  PreOrderCanceled = "order_fulfillment.preorder_canceled"
}

export interface PusherOnEvent {
  action: Callback;
  event: string;
}

export interface PusherEvent {
  broadcast: string;
  channels: string[];
  payload: any[];
  message: string;
}
