import { policyList } from "@/enums/permissions";
import { fnsFormatToLocalDatetime } from "@/filters/date-fns.filter";
import { Location } from "@/interfaces/location";
import { Timeclock } from "@/interfaces/timeclock";
import { User } from "@/interfaces/user";
import { EventBus } from "@/internal";
import { BooleanCheck, Callback } from "@/types/types";
import {
  addTime,
  addTimeZeros,
  FNS_DATE_FORMATS,
  fnsDurationAsLocalTime,
  fnsFormatDate
} from "@/utils/date-fns.utils";
import subHours from "date-fns/subHours";
import subMinutes from "date-fns/subMinutes";
import subSeconds from "date-fns/subSeconds";
import { CallbackPromise } from "helix-vue-components";
// @ts-ignore
import VueFlip from "vue-flip";
import { Component, Prop, Vue } from "vue-property-decorator";
import { Action, Getter, State } from "vuex-class";
import { mockUserTimeClock } from "../../dashboard.utils";
import { CardContainerComponent } from "./../CardContainer/CardContainer.component";
import { TimeclockBackComponent } from "./TimeclockBack/TimeclockBack.component";
import Template from "./TimeclockCard.template.vue";
import TimeclockTimeComponent from "./TimeclockTime/TimeclockTime.component";

const namespace: string = "TimeclockModule";
@Component({
  mixins: [Template],
  components: {
    VueFlip,
    TimeclockTimeComponent,
    TimeclockBackComponent,
    "card-container": CardContainerComponent
  },
  filters: {
    fnsFormatToLocalDatetime
  }
})
export default class TimeclockCardComponent extends Vue {
  @State("user", { namespace: "AuthModule" }) public user!: User;

  @Prop({ required: true })
  public canEditGraphs!: boolean;

  @Prop({ required: true })
  public position!: number;

  @Prop({ required: true })
  public item!: object;

  @Action("findUserTimeclocks", { namespace })
  public findUserTimeclocks!: Callback;

  @Action("findUserTimeWeek", { namespace })
  public findUserTimeWeek!: Callback;

  @Action("checkInTimeclock", { namespace })
  public checkInTimeclock!: Callback;

  @Action("checkOutTimeclock", { namespace })
  public checkOutTimeclock!: CallbackPromise<void>;

  @Action("breakInTimeclock", { namespace })
  public breakInTimeclock!: Callback;

  @Action("breakOutTimeclock", { namespace })
  public breakOutTimeclock!: Callback;

  @Getter("userTimeclocks", { namespace })
  public userTimeclocks!: Timeclock[];

  @Getter("userTimeWeek", { namespace })
  public userTime!: string;

  @Getter("currentLocation", { namespace: "AuthModule" })
  public currentLocation!: Location;

  @Getter("loading", { namespace })
  public loading!: boolean;

  @Getter("hasPermission", { namespace: "PermissionsModule" })
  public hasPermission!: BooleanCheck;

  public mockUserTimeClocks = mockUserTimeClock;
  public permission = false;
  public i: number = 0;
  public currentTime = "";
  protected currentTimeInterval = 0;
  protected workCount: string = "";

  get onBreak() {
    return this.lastTimeclock && this.lastTimeclock.status === "BREAK_TIME";
  }

  get lastTimeclock() {
    return this.userTimeclocks[0];
  }

  get isWorking() {
    const isWorking =
      this.lastTimeclock &&
      (this.lastTimeclock.status === "OPENED" ||
        this.lastTimeclock.status === "BREAK_TIME");
    if (isWorking && !this.onBreak) {
      this.workCount = this.calculateWorkCount();
    }
    return isWorking;
  }

  public startDay() {
    const data = {
      user_id: this.user.id,
      registered_date: fnsFormatDate(new Date())
    };
    this.checkInTimeclock(data);
    this.i++;
  }

  public async endDay() {
    const data = {
      timeclock_id: this.lastTimeclock.id,
      user_id: this.user.id
    };
    await this.checkOutTimeclock(data);
    this.workCount = "";
    this.i++;
    EventBus.$emit("hasCheckedOut");
  }

  public toggleBreak() {
    // if onBreak is false then make call to backend and start counting break
    // else if break is true, then make call to backend and stop break
    const data = {
      timeclock_id: this.lastTimeclock.id,
      user_id: this.user.id
    };
    this.onBreak ? this.breakOutTimeclock(data) : this.breakInTimeclock(data);
    if (this.onBreak) {
      this.workCount = this.lastTimeclock.work_for!;
    }
    this.i++;
  }

  public calculateWorkCount() {
    const timeDiff = fnsDurationAsLocalTime(
      new Date(this.lastTimeclock.check_in),
      new Date()
    );
    const breakTime = this.lastTimeclock.break_for!;

    if (breakTime) {
      const timeDiffSplit = timeDiff.split(":");
      const breakTimeSplit = breakTime.split(":");
      let date = new Date(
        0,
        0,
        0,
        +timeDiffSplit[0],
        +timeDiffSplit[1],
        +timeDiffSplit[2]
      );
      date = subHours(date, +breakTimeSplit[0]);
      date = subMinutes(date, +breakTimeSplit[1]);
      date = subSeconds(date, +breakTimeSplit[2]);

      return addTimeZeros(
        `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`
      );
    }
    return timeDiff;
  }

  public calculateBreakCount(receiver: string) {
    const events = this.lastTimeclock.timeclock_events!;
    const lastEvent = events[events.length - 1];
    const isBreakIn = lastEvent.event === "BREAK_TIME_IN";
    let timeDiff = receiver;
    if (isBreakIn) {
      timeDiff = fnsDurationAsLocalTime(
        new Date(lastEvent.registered_at),
        new Date()
      );
    }

    if (timeDiff !== receiver) {
      return addTime(receiver, timeDiff);
    }
    return receiver;
  }

  get breakCount() {
    const breakCounting = "00:00:00";
    if (this.isWorking && this.lastTimeclock.break_for !== null) {
      return this.calculateBreakCount(this.lastTimeclock.break_for!);
    }
    return breakCounting;
  }

  get breakData(): Date | false {
    const events = this.lastTimeclock.timeclock_events!;
    const lastEvent = events[events.length - 1];
    const isBreakIn = lastEvent.event === "BREAK_TIME_IN";
    return isBreakIn ? new Date(lastEvent.registered_at) : false;
  }

  public mounted() {
    if (!this.canEditGraphs) {
      this.findUserTimeclocks(this.user.id);
      this.findUserTimeWeek(this.user.id);
    }
    if (this.canEditGraphs) {
      this.permission = !this.hasPermission(policyList.employeeTimeClock);
    }
    this.currentTimeInterval = setInterval(() => {
      this.currentTime = fnsFormatDate(new Date(), FNS_DATE_FORMATS.LT);
    }, 1000);
  }

  protected beforeDestroy() {
    clearInterval(this.currentTimeInterval);
  }
}
